<template>
  <transition name="block-tabs">
    <div class="tabs" v-if="(!onlyAny && !isSingleBlockOn) || isEventBasedPlan">
      <ul>
        <li v-for="(tab, index) in tabs" :key="index" :class="{ 'is-active': view.activeBlock === tab.value }">
          <a @click.prevent="changeTab(tab.value)">
            {{ tab.name }}
            &nbsp;<span>{{ $t("blockTabs.block") }}</span>
          </a>
        </li>
      </ul>
    </div>
  </transition>
</template>
<script>
import { mapGetters, mapState } from "vuex"
import { BLOCK_TYPES } from "@/types"

export default {
  name: "BlockTabs",
  watch: {
    calculatedBlocks(newVal, oldVal) {
      if (newVal.length !== oldVal.length || newVal[0].blockType !== oldVal[0].blockType) {
        this.blockType = newVal[0].blockType
        this.$store.dispatch("plan/changeActiveBlock", this.blockType)
        this.changeTab(this.blockType)
      }
    }
  },
  data() {
    return {
      blockType: "",
      blockValues: {}
    }
  },
  computed: {
    ...mapState("plan", ["view", "result", "values"]),
    ...mapGetters("plan", ["getPlanConfigValue", "calculatedBlocks"]),

    tabs() {
      const blocks = this.calculatedBlocks.map(block => block.blockType)
      const tabs = blocks.map(b => {
        return {
          value: b,
          name: this.$t(`common.blockTypes.${b}`)
        }
      })
      return tabs
    },
    isSingleBlockOn() {
      return this.getPlanConfigValue("isSingleBlockOn")
    },
    isEventBasedPlan() {
      return this.getPlanConfigValue("isEventBasedPlan")
    },
    onlyAny() {
      return this.calculatedBlocks.length === 1 && this.calculatedBlocks[0].blockType === BLOCK_TYPES.ANY
    }
  },
  methods: {
    changeTab(tab) {
      this.blockType = tab
      this.$store.dispatch("plan/changeActiveBlock", this.blockType)
    }
  },
  created() {
    const isEventBasedPlan = this.getPlanConfigValue("isEventBasedPlan")
    let tab = ""
    if (isEventBasedPlan) {
      tab = this.calculatedBlocks.map(block => block.blockType)[0]
    } else {
      tab = BLOCK_TYPES.ANY
    }
    if (tab && this.values) {
      // when this.blocks is empty tab === undefined
      this.changeTab(tab)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

.tabs {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  overflow: unset;
  align-items: center;

  margin: 15px 0;

  ul {
    border: none;
  }

  a {
    border-bottom-color: $light-blue3;
    border-bottom-width: 3px;
    text-transform: uppercase;
    font-weight: bold;
    span {
      font-weight: normal;
      text-transform: none;

      @include media("<phone") {
        display: none;
      }
    }
  }
}

.tabs li.is-current:not(.is-active) a {
  color: #71b4fe;
}

.no-tabs {
  padding: 20px 0 13px 0;
  span {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
  }
}

.block-tabs-enter-active,
.block-tabs-leave-active,
.block-tabs-enter-to {
  transition: all 0.2s ease-in-out;
  opacity: 1;
  height: 100%;
  margin: 15px 0;
}
.block-tabs-enter, .block-tabs-leave-to,  /* .block-tabs-leave-active до версии 2.1.8 */ {
  opacity: 0;
  height: 0;
  margin: 0;
  transition: all 0.2s ease-in-out;
}
</style>
