<template>
  <div>
    <div ref="barChart" class="weeks-chart">
      <div class="weeks-chart-legend">
        <div class="item gray" style="display: none">
          Upcoming Weeks
        </div>
      </div>
      <div v-if="weeks.length === 0" class="weeks-chart-no-data">
        <img class="page-loader-icon" src="@/assets/images/loader.gif" alt="Loader" />
      </div>
    </div>
  </div>
</template>
<script>
import debounce from "lodash.debounce"
import { create, select, event } from "d3-selection"
import { extent } from "d3-array"
import { axisLeft, axisBottom } from "d3-axis"
import { scaleBand, scaleLinear } from "d3-scale"

const CHART_MARGIN_LEFT = 30
const CHART_MARGIN_TOP = 35
const BAR_BORDER_RADIUS = 2

export default {
  name: "BarChart",
  props: {
    weeks: {
      type: Array,
      default: () => []
    },
    chartWidth: {
      type: [Number, String],
      default: "100%"
    },
    chartHeight: {
      type: Number,
      default: 260
    },
    firstname: {
      type: String,
      default: null
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    xAxisLabel: {
      type: String,
      default: "Weeks"
    }
  },
  data() {
    return {
      width: 0,
      height: 0,
      offsetTopChart: 0,
      svg: null,
      tooltip: null,
      debouncedResize: null
    }
  },
  mounted() {
    this.$nextTick(() => this.init())

    this.debouncedResize = debounce(() => {
      this.init()
    }, 100)

    window.addEventListener("resize", this.debouncedResize)

    this.offsetTopChart = this.$refs.barChart.offsetTop
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedResize)
  },
  watch: {
    weeks() {
      this.init()
    },
    isMobile() {
      this.debouncedResize()
    }
  },
  computed: {
    x() {
      const { width } = this
      return scaleBand()
        .domain(this.weeks.map(([, weekNumber]) => weekNumber))
        .range([0, width - CHART_MARGIN_LEFT])
        .padding(0.1)
    },

    y() {
      const { height } = this
      const [, maxValue] = extent(this.weeks.map(([hours]) => hours))
      const maxYAxisValue = maxValue + maxValue * (this.isMobile ? 0.7 : 0.4)
      return scaleLinear()
        .domain([0, maxYAxisValue])
        .range([height - CHART_MARGIN_TOP, 0])
    },

    chartNameTitle() {
      const { firstname } = this
      if (firstname) {
        const last = firstname.charAt(firstname.length - 1)
        const name = last === "s" ? `${firstname}’` : `${firstname}’s`
        return `${name} Hours per Week`
      }
      return `Your Hours per Week`
    }
  },
  methods: {
    init() {
      if (this.svg) this.svg.remove()
      if (this.tooltip) this.tooltip.remove()

      this.svg = create("svg")
        .attr("width", this.chartWidth)
        .attr("height", this.chartHeight)
        .attr("style", "display: block;")

      this.$el.children[0].appendChild(this.svg.node())
      const { width, height } = this.svg.node().getBoundingClientRect()
      this.width = width
      this.height = height

      const chart = this.svg.append("g").attr("transform", `translate(${CHART_MARGIN_LEFT}, -${CHART_MARGIN_TOP})`)

      this.tooltip = select(".weeks-chart")
        .append("div")
        .attr("class", "tooltip-bar-chart")

      this.drawAxis(chart)
      this.drawBars(chart)
      this.drawLabels()
    },

    drawAxis(chart) {
      const { height, width, x, y } = this
      const makeYLines = () =>
        axisLeft()
          .scale(y)
          .tickArguments([4, "~s"])

      chart
        .append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(axisBottom(x))
        .call(g => g.select(".domain").remove())

      chart
        .append("g")
        .call(
          axisLeft(y)
            .tickSize(1)
            .tickPadding(8)
            .tickArguments([4, "~s"])
        )
        .call(g => g.select(".domain").remove())

      chart
        .append("g")
        .attr("class", "grid")
        .call(
          makeYLines()
            .tickSize(-width, 0, 0)
            .tickFormat("")
        )
    },

    drawBars(chart) {
      const barGroups = chart
        .selectAll()
        .data(this.weeks.map(([hours]) => hours))
        .enter()
        .append("g")
        .attr("class", "bar-g")

      barGroups
        .data(this.weeks)
        .append("path")
        .attr("class", ([, , isActive]) => (isActive ? "bar blue-bar" : "bar semi-blue-bar"))
        .attr("data-hours", ([hours]) => hours)
        .attr("d", this.makeRect)
        .on("mousemove", this.onMousemoveBar)
        .on("mouseout", () => {
          this.tooltip.style("display", "none")
        })
    },

    makeRect(d) {
      const [hours, weekNumber] = d
      const { x, y, height } = this
      const rx = BAR_BORDER_RADIUS
      const ry = BAR_BORDER_RADIUS

      return `
        M${x(weekNumber)},${y(hours) + ry}
        a${rx},${ry} 0 0 1 ${rx},${-ry}
        h${x.bandwidth() - 2 * rx}
        a${rx},${ry} 0 0 1 ${rx},${ry}
        v${height - y(hours) - ry}
        h${-x.bandwidth()}Z
      `
    },
    drawLabels() {
      const { height, width } = this
      this.svg
        .append("text")
        .attr("class", "axis-label")
        .attr("x", 25)
        .attr("y", height - 5)
        .attr("text-anchor", "middle")
        .text(`${this.$t("common.hrs")}`)

      this.svg
        .append("text")
        .attr("class", "axis-label")
        .attr("x", width / 2)
        .attr("y", height - 5)
        .attr("text-anchor", "middle")
        .text(`${this.xAxisLabel}`)
    },
    onMousemoveBar([hours]) {
      let top = event.offsetY
      let left = event.offsetX

      if (event.pageY < 50) {
        top = event.offsetY + 20
      }

      if (window.innerWidth < event.pageX + 70) {
        left = event.offsetX - 70
      }

      this.tooltip
        .style("left", left + "px")
        .style("top", top + "px")
        .style("display", "inline-block")
        .style("position", "absolute")
        .html(`<strong style="text-transform: capitalize">${this.$t("common.hours")}</strong>: ${hours}`)
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

$left-shift: 15px;

.weeks-chart {
  position: relative;

  &.weeks-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 2000;
    padding: 6px 16px;
    border-bottom: 2px solid #d9ecff;

    @include media(">phone") {
      padding: 6px 41px;
    }
  }

  svg {
    margin-left: -$left-shift;
  }

  &-name {
    color: #131415;
    font-weight: 600;
    padding-left: 15px;
    margin-bottom: 5px;
  }
  &-legend {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    margin-bottom: 5px;
    gap: 16px;

    .item {
      display: flex;
      align-items: center;

      &::before {
        content: "";
        margin-right: 5px;
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 2px;
      }
    }

    .item.gray {
      &::before {
        background-color: $gray13;
      }
    }

    .item.blue {
      &::before {
        background-color: $blue;
      }
    }
  }
  &-no-data {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    color: #aaaaaa;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tooltip-bar-chart {
    pointer-events: none;
    position: absolute;
    display: none;
    min-width: 70px;
    font-size: 10px;
    color: #131415;
    border: 1px solid #aaa;
    padding: 0 0;
    text-align: center;
    border-radius: 5px;
    background-color: #ffffff;
    transition: 1s left, 1s top;
  }

  svg {
    width: calc(100% + #{$left-shift});
    height: 100%;
  }

  .bar {
    &:hover {
      filter: contrast(0.8);
    }
  }

  .grey-bar {
    fill: $gray13;
  }

  .blue-bar {
    fill: $blue;
  }

  .semi-blue-bar {
    fill: $semi-blue;
  }

  .axis-label {
    font-size: 12px;
    fill: #b7b7b7;
    text-transform: capitalize;
  }
  text {
    font-size: 10px;
    fill: #131415;
    user-select: none;
  }

  .grid path {
    stroke-width: 0;
  }

  line,
  .grid .tick line {
    stroke: #9faaae;
    stroke-opacity: 0.3;
  }
}
</style>
