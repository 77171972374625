<template>
  <div class="block-settings" :class="{ 'block-disabled': isBlockDisabled }">
    <BlockTypeAndPlanDescription />

    <HoursAndChart />

    <SimpleTrainingDays />
  </div>
</template>

<script>
import BlockTypeAndPlanDescription from "@/components/plan/MainArea/Settings/BlockTypeAndPlanDescription.vue"
import HoursAndChart from "./HoursAndChart.vue"
import SimpleTrainingDays from "./TrainingDays.vue"
import { mapGetters, mapState } from "vuex"
export default {
  name: "BlockSettings",
  components: {
    HoursAndChart,
    BlockTypeAndPlanDescription,
    SimpleTrainingDays
  },
  computed: {
    ...mapState("plan", ["view"]),
    ...mapGetters("plan", ["disabledBlocks"]),

    isBlockDisabled() {
      return this.disabledBlocks.includes(this.view.activeBlock)
    }
  }
}
</script>

<style scoped lang="scss">
.block {
  &-settings {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
  }
  &-disabled {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 10px;
      cursor: not-allowed;
    }
  }
}
</style>
