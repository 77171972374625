<template>
  <div>
    <div class="cj-field">
      <div class="cj-field-content">
        <div class="cj-field-header">
          <div class="cj-label">
            <div class="cj-label-title">{{ $t("planDuration.no-event") }} &nbsp;</div>
          </div>
        </div>

        <ButtonSelect :options="planDurationValues" v-model="planDuration" />
        <label v-if="planDuration === WEEKS_DURATION_CHOOSE_MULTI_BLOCKS" class="toggle">
          <Toggle v-model="isSingleBlockOff" :options="options" />
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import {
  EIGHT_WEEKS,
  FOUR_WEEKS,
  MAX_WEEKS_DURATION,
  MIN_WEEKS_DURATION,
  ONE_YEAR_WEEKS,
  SIX_MONTHS_WEEKS,
  SIXTEEN_WEEKS,
  TWELVE_WEEKS,
  WEEKS_DURATION_CHOOSE_MULTI_BLOCKS
} from "@/defaults"
import ButtonSelect from "@/components/ButtonSelect"
import Toggle from "@/components/Toggle"
import { mapGetters, mapActions } from "vuex"

export default {
  name: "PlanWeeks",
  watch: {
    isSingleBlockOff(newValue) {
      if (!newValue) {
        this.$store.dispatch("plan/changeActiveBlock", "any")
      }
    }
  },
  components: {
    ButtonSelect,
    Toggle
  },
  data() {
    return {
      MAX_WEEKS_DURATION,
      MIN_WEEKS_DURATION,
      WEEKS_DURATION_CHOOSE_MULTI_BLOCKS
    }
  },
  methods: {
    ...mapActions("plan", ["updatePlanConfigValue"])
  },
  computed: {
    ...mapGetters("plan", ["getTotalWeeksFromDates", "getPlanConfigValue"]),

    planDurationValues() {
      const weeks = [FOUR_WEEKS, EIGHT_WEEKS, TWELVE_WEEKS, SIXTEEN_WEEKS, SIX_MONTHS_WEEKS, ONE_YEAR_WEEKS]

      return weeks.map(val => {
        let humanizedDuration = this.$tc("planDuration.weeksOrWeek", val, { count: val }) // We use $tc for declensions
        let disabled = false
        let prompt = null

        if (val === ONE_YEAR_WEEKS) {
          disabled = true
          const oneYear = 1
          humanizedDuration = `${oneYear} ` + this.$t("common.year")
          prompt = this.$t("common.comingSoon")
        }
        if (val === SIX_MONTHS_WEEKS) {
          const halfYearMonths = 6
          humanizedDuration = `${halfYearMonths} ` + this.$t("common.months")
        }
        return {
          name: humanizedDuration,
          value: val,
          disabled,
          prompt
        }
      })
    },
    planDuration: {
      get() {
        return this.getPlanConfigValue("singleMultiBlockDurationWeeks")
      },
      set(value) {
        const updates = []
        if (value) {
          updates.push({ name: "singleMultiBlockDurationWeeks", value })

          if (value > WEEKS_DURATION_CHOOSE_MULTI_BLOCKS) {
            updates.push({ name: "isSingleBlockOn", value: false })
          }

          if (value < WEEKS_DURATION_CHOOSE_MULTI_BLOCKS) {
            updates.push({ name: "isSingleBlockOn", value: true })
          }

          this.updatePlanConfigValue(updates)
        }
      }
    },
    isSingleBlockOn: {
      get() {
        return this.getPlanConfigValue("isSingleBlockOn")
      },
      set(value) {
        this.updatePlanConfigValue({ name: "isSingleBlockOn", value })
      }
    },
    isSingleBlockOff: {
      get() {
        return !this.isSingleBlockOn
      },
      set(value) {
        this.isSingleBlockOn = !value
      }
    },
    options() {
      return [this.$t("planDuration.single"), this.$t("planDuration.multipleBlocks")]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

.cj-field {
  display: grid;
  grid-template-columns: auto 475px;
  align-items: flex-end;
  margin: 10px 0;
  border-radius: 0 0 10px 10px;

  @include media("<desktop") {
    flex-direction: column;
    grid-template-columns: auto;
    align-items: center;
    gap: 30px;
    border-radius: 0 10px 10px 10px;
  }
  @include media("<phone") {
    display: flex;
    gap: 16px;
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-content {
    @include media("<tablet") {
      max-width: unset;
      min-width: 135px;
    }
  }
}
.events-date {
  display: flex;
  flex-direction: column;
}
.date {
  &-blocks {
    display: none;
    gap: 50px;
    margin-top: 20px;
  }
  &-block {
    position: relative;
    p {
      font-weight: 500;
    }

    .error {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      color: red;
    }
  }
}

.under-label {
  font-size: 12px;
}

.date-input {
  margin-top: 11px;
  display: flex;
  align-items: center;
  gap: 30px;
  font-weight: 500;
  flex-wrap: wrap;

  @include media("<tablet") {
    gap: 15px;
  }

  .starting-date {
    margin-top: -20px;
    flex: 1 1 100%;
    font-size: 12px;
    font-weight: normal;
  }
}
.start-date {
  display: flex;
  align-items: center;
}
.error {
  color: $red;
  text-align: center;
}
.blink {
  animation: blinker 1s linear infinite;
}
.toggle {
  margin-top: 10px;
}
a {
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  color: $black;
}
</style>
