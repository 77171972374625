<template>
  <div class="cj-field">
    <div class="cj-label cj-label-flex">
      <div class="cj-label-title">
        {{ $t("intensityLevel.title") }}&nbsp;
        <InfoModal :text="$t('intensityLevel.infoText')" :title="$t('intensityLevel.infoTitle')" />
        <div style="display: none" class="">{{ currentIntervalIntensityLevelNamed }}</div>
      </div>
    </div>

    <vue-slider
      class="range-slider"
      v-model="currentIntervalIntensityLevel"
      :data="currentIntervalIntensityLevels"
      :height="6"
      :tooltip="null"
      :contained="true"
      dotSize="28"
      :railStyle="{ background: '#abd3ff' }"
      :processStyle="{ background: '#2c68de' }"
      :disabled="isDisabled"
    >
      <template v-slot:dot>
        <div class="custom-tooltip">{{ currentIntervalIntensityLevel }}</div>
      </template>
    </vue-slider>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import InfoModal from "@/components/InfoModal"

export default {
  name: "IntensityLevel",
  components: {
    InfoModal
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    blockCurrentIntervalIntensityLevel: {
      type: Number,
      default: null
    },
    blockSecondaryIntensity: {
      type: Number,
      default: 0
    },
    blockSecondaryType: {
      type: String,
      default: "default"
    }
  },
  watch: {
    currentIntervalIntensityLevel(value) {
      this.$emit("currentIntervalIntensityLevel", value)
    },
    blockCurrentIntervalIntensityLevel(value) {
      this.currentIntervalIntensityLevel = value
    },
    blockSecondaryIntensity(value) {
      this.secondaryIntensity = value
    }
  },
  data() {
    return {
      currentIntervalIntensityLevel: 0,
      showSecondaryIntensityModal: false,
      secondaryIntensity: 0
    }
  },
  computed: {
    ...mapGetters(["difficultyLevel"]),

    currentIntervalIntensityLevelNamed() {
      const levels = ["Easy", "Moderate", "Hard", "Very Hard"]
      const level = this.currentIntervalIntensityLevel - this.difficultyLevel
      return levels[level]
    },
    currentIntervalIntensityLevels() {
      return Array.from({ length: 10 }, (_, i) => i + this.difficultyLevel + 1)
    }
  },
  mounted() {
    this.currentIntervalIntensityLevel = this.blockCurrentIntervalIntensityLevel
    this.secondaryIntensity = this.blockSecondaryIntensity
  }
}
</script>

<style scoped>
.cj-field {
  background: none;
  border: none;
  padding: unset;
}
</style>
