<template>
  <BlueHeaderModal @close="$emit('close')" class="create-custom-block-modal">
    <template slot="header">
      <p class="label">{{ $t("customModal.title") }}</p>
    </template>
    <template slot="body">
      <div class="tabs is-boxed">
        <ul>
          <li :class="{ 'is-active': tab === HARDER_TAB }">
            <a @click="setTab(HARDER_TAB)">{{ $t("customModal.harder") }}</a>
          </li>
          <li :class="{ 'is-active': tab === CUSTOM_TAB }">
            <a @click="setTab(CUSTOM_TAB)">{{ $t("customModal.custom") }}</a>
          </li>
        </ul>
      </div>

      <div class="cj-field" :class="{ 'is-custom-tab': tab === CUSTOM_TAB }">
        <ButtonSelect v-if="tab === HARDER_TAB" :options="getHarderTypes" v-model="harderPlanType" />
        <div v-if="tab === CUSTOM_TAB" class="custom-tab">
          <div class="left">
            <div class="field text-left mb-24">
              <label class="label">{{ $t("customModal.sequence") }} 1</label>
              <div class="select">
                <select type="text" ref="sequence1" v-model="sequences[0]">
                  <option v-for="option in options" :key="option.value" :value="option.value">{{ option.name }}</option>
                </select>
              </div>
            </div>

            <div class="field text-left mb-24">
              <label class="label">{{ $t("customModal.sequence") }} 2</label>
              <div class="select">
                <select type="text" ref="sequence2" v-model="sequences[1]">
                  <option v-for="option in options" :key="option.value" :value="option.value">{{ option.name }}</option>
                </select>
              </div>
            </div>

            <div class="field text-left">
              <label class="label">{{ $t("customModal.sequence") }} 3</label>
              <div class="select">
                <select type="text" ref="sequence3" v-model="sequences[2]">
                  <option v-for="option in options" :key="option.value" :value="option.value">{{ option.name }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="field text-left mb-24">
              <label class="label">{{ $t("customModal.planName") }}</label>
              <input v-model="name" class="input" type="text" ref="name" placeholder="Block Name *" required />
            </div>
            <div class="field text-left">
              <label class="label">{{ $t("customModal.description") }}</label>
              <textarea name="" id="" class="text-area" v-model="description"></textarea>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <button :disabled="isSaveDisabled" class="button is-full-width is-green" @click="save">
        <span>{{ $t("common.save") }}</span>
      </button>
    </template>
  </BlueHeaderModal>
</template>

<script>
import BlueHeaderModal from "@/components/BlueHeaderModal"
import ButtonSelect from "@/components/ButtonSelect.vue"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { CUSTOM_TAB, HARDER_TAB } from "./custom-modal-tabs"
import { PLAN_TYPES } from "@/types"

export default {
  name: "CustomModal",
  components: { ButtonSelect, BlueHeaderModal },
  props: {
    planType: {
      type: String,
      default: ""
    },
    customPlanModalTab: {
      type: String,
      default: null
    },
    customPlan: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isNew: false,
      name: "",
      tab: HARDER_TAB,
      harderPlanType: null,
      sequences: [],
      description: "",

      HARDER_TAB,
      CUSTOM_TAB
    }
  },
  computed: {
    ...mapState("plan", ["values"]),
    ...mapGetters("plan", ["getBlockConfigValue"]),
    getTypeValues() {
      const { secondaryIntensityValues } = this.values
      return secondaryIntensityValues
    },
    blockPlanType() {
      return this.getBlockConfigValue("planType")
    },
    currentIntervalIntensityLevel() {
      return this.getBlockConfigValue("currentIntervalIntensityLevel")
    },
    isDisabled() {
      return this.name
    },
    getHarderTypes() {
      return this.values.harderTypeValues
    },
    isSaveDisabled() {
      return this.tab === this.HARDER_TAB && !this.harderPlanType
    },
    options() {
      return [
        { value: "sfr", name: this.$t("customModal.sfr") },
        { value: "threshold", name: this.$t("customModal.threshold") },
        { value: "thresholdBlock", name: this.$t("customModal.thresholdBlock") },
        { value: "dynamicForce", name: this.$t("customModal.dynamicForce") },
        { value: "sweetSpotA", name: this.$t("customModal.sweetSpotA") },
        { value: "sweetSpotB", name: this.$t("customModal.sweetSpotB") },
        { value: "sweetSpotBlock", name: this.$t("customModal.sweetSpotBlock") },
        { value: "hiitA", name: this.$t("customModal.hiitA") },
        { value: "hiitB", name: this.$t("customModal.hiitB") },
        { value: "tempoBlock", name: this.$t("customModal.tempoBlock") },
        { value: "z2", name: this.$t("customModal.z2") },
        { value: "z2_micro_sprints", name: this.$t("customModal.z2MicroSprints") },
        { value: "long", name: this.$t("customModal.long") },
        { value: "vo2max", name: this.$t("customModal.vo2max") },
        { value: "vo2max_crunched", name: this.$t("customModal.vo2max_crunched") },
        { value: "vo2max_favorite", name: this.$t("customModal.vo2max_favorite") },
        { value: "vo2max_long", name: this.$t("customModal.vo2max_long") }
      ]
    }
  },
  methods: {
    ...mapActions("plan", ["updateActiveBlockConfigValue"]),
    ...mapMutations("plan", ["setActiveBlockConfigValue"]),

    setTab(value) {
      this.tab = value
    },

    save() {
      const { isNew } = this
      const isHarder = this.tab === this.HARDER_TAB

      let currentIntervalIntensityLevel = 1
      let secondaryIntensity = this.getTypeValues[0].value

      if (isHarder) {
        secondaryIntensity = this.getTypeValues[2].value
        currentIntervalIntensityLevel = this.currentIntervalIntensityLevel > 5 ? this.currentIntervalIntensityLevel : 5
        this.updateActiveBlockConfigValue([
          { name: "currentIntervalIntensityLevel", value: currentIntervalIntensityLevel },
          { name: "secondaryIntensity", value: secondaryIntensity },
          { name: "secondaryType", value: "default" },
          { name: "planType", value: this.harderPlanType }
        ])
        this.$emit("save", { isHarder })
        return
      }

      if (this.planType !== PLAN_TYPES.CUSTOM) {
        this.setActiveBlockConfigValue([
          { name: "currentIntervalIntensityLevel", value: currentIntervalIntensityLevel },
          { name: "secondaryType", value: "default" },
          { name: "secondaryIntensity", value: secondaryIntensity }
        ])
      }

      const customPlan = {
        name: this.name,
        sequences: this.sequences,
        description: this.description,
        isExist: true
      }

      this.$emit("save", { customPlan, isNew })
    },

    setHarderPlanType() {
      const isHarder = this.getHarderTypes.map(item => item.value).includes(this.blockPlanType)
      if (isHarder) {
        this.harderPlanType = this.blockPlanType
      }
    }
  },
  created() {
    if (this.customPlanModalTab) {
      this.tab = this.customPlanModalTab
    }

    this.setHarderPlanType()

    if (this.customPlan) {
      this.name = this.customPlan.name
      this.sequences = [...this.customPlan.sequences]
      this.description = this.customPlan.description
    } else {
      this.isNew = true

      this.name = ""
      this.sequences = ["sfr", "threshold", "long"]
      this.description = ""
    }
  },
  beforeMount() {
    document.querySelector(".app-root").classList.add("is-modal-open")
  },
  beforeDestroy() {
    document.querySelector(".app-root").classList.remove("is-modal-open")
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.base-modal-mask {
  padding: 20px;
  align-items: center;
}
::v-deep {
  .base-modal-wrapper {
    max-width: 90%;
    width: 100%;
  }
  .blue-header-modal-header .field {
    width: 70%;
  }
  .blue-header-modal-container {
    max-width: 800px;
    min-width: 800px;
    @include media("<=tablet") {
      max-width: 100%;
      min-width: 100%;
    }
  }

  .blue-header-modal-body {
    display: flex;
    flex-direction: column;
    padding: 16px 50px 16px 60px;
    @include media("<=tablet") {
      flex-direction: column;
      padding: 20px;
      max-height: 500px;
      min-height: 500px;
      overflow-y: scroll;
    }

    .left,
    .right {
      flex: 1 1 0;
    }

    .label {
      font-weight: 500;
      color: #131415;
    }

    .select {
      width: 100%;

      &:after {
        border-color: #0f101199;
      }

      select {
        width: 100%;
      }
    }

    .text-area {
      width: 100%;
      resize: none;
      margin: 0;
      height: 130px;
      border-color: #d6d6d6;
      border-radius: 8px;
      padding: 15px;
    }
  }
  .empty {
    display: none;
  }
}

.custom-tab {
  display: flex;
  flex-direction: row;
  grid-gap: 40px;
  justify-content: space-between;
  @include media("<tablet") {
    flex-direction: column;
  }
}
.cj-field {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  min-height: 300px;
  border-radius: 0 10px 10px 10px;
  justify-content: space-between;
  &.is-custom-tab {
    min-height: unset;
  }
}

.tabs {
  margin-bottom: 0;
  position: relative;
  border-bottom: none;
  border-radius: 6px 6px 0 0;
  overflow: visible;
}

.is-active {
  border: 2px solid #d9ecff;
  border-bottom: 0;
  z-index: 1;

  a {
    background-color: #ecf5ff !important;
  }
}

ul {
  border-style: none;
}

li {
  position: relative;
  margin-bottom: -2px;
  border: 2px solid transparent;
  border-bottom: none;
  border-radius: 6px 6px 0 0;
}

.tabs.is-boxed {
  a {
    border: none;
  }

  li:not(.is-active) {
    a:hover {
      box-shadow: inset 0 -2px 0 0 #d9ecff;
    }
  }
}

a {
  border-style: none;
  margin-bottom: 0;
}
</style>
