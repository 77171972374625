<template>
  <BlueHeaderModal @close="$emit('close')">
    <template v-slot:header>
      <label class="label">{{ $t("planDuration.multipleEventsTitle") }}</label>
    </template>
    <template v-slot:body>
      <div class="body-multiple-events is-flex is-justify-content-space-between is-align-items-center">
        <div class="field text-left mb-24">
          <label class="label">Date</label>
          <EventDateInput :isMultipleEvents="true" @change="date => (userDate = date)" :date="userDate" />
        </div>
        <div class="field text-left mb-24">
          <label class="label">Duration</label>
          <div class="select">
            <select type="text" v-model="duration">
              <option v-for="option in durationOptions" :key="option.value" :value="option.value">{{
                option.name
              }}</option>
            </select>
          </div>
        </div>
        <div class="field text-left mb-24">
          <label class="label">Type</label>
          <div class="select">
            <select type="text" v-model="type" :disabled="isTypeDisabled">
              <option v-for="option in typeOptions" :key="option.value" :value="option.value">{{ option.name }}</option>
            </select>
          </div>
        </div>
        <div class="field text-left mb-24">
          <label class="label">&nbsp;</label>
          <button
            v-if="editEventIndex === -1"
            class="button action-button"
            :class="userDate && type && duration ? 'is-red' : 'is-green'"
            @click="addEvent"
          >
            ADD
          </button>
          <div class="is-flex" v-else>
            <button class="button is-green action-button" @click="saveEvent">
              SAVE
            </button>
            <button class="button is-light action-button" @click="cancelEdit">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div class="table-container">
        <div class="table-empty" v-if="!userMultipleEvents.length">
          {{ $t("planDuration.noMultipleEvents") }}
        </div>
        <table class="table is-bordered is-striped table-multiple-events">
          <thead>
            <tr>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(event, eventIndex) in userMultipleEvents" :key="event.eventDate">
              <td class="td-date" v-html="getEvent(event)"></td>
              <td>
                <div class="is-flex is-justify-content-center action-buttons">
                  <a v-if="!event.primary" class="button-delete" @click="deleteEvent(eventIndex)">
                    <svg-icon icon="delete" />
                  </a>
                  <a class="button-edit" @click="editEvent(eventIndex)">
                    <svg-icon icon="edit" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-slot:footer>
      <button class="button is-full-width is-green" :disabled="editingEvent" @click="save">
        <span>{{ $t("common.save") }}</span>
      </button>
    </template>
  </BlueHeaderModal>
</template>

<script>
import BlueHeaderModal from "@/components/BlueHeaderModal"
import moment from "moment"
import { mapGetters, mapState } from "vuex"
import EventDateInput from "@/components/plan/PlanDurationAndEventDate/EventDateInput.vue"

const typeOptions = [
  { value: "a-race", name: "A Race" },
  { value: "b-race", name: "B Race" },
  { value: "c-race", name: "C Race" },
  { value: "event-race", name: "Event" }
]

const [firstType] = typeOptions
const defaultType = firstType.value

const getEventName = type => typeOptions.find(({ value }) => value === type)?.name || ""

const twoDaysValue = "2d"
const threeDaysValue = "3d"

const durationOptions = [
  { value: "1h", name: "1 hour" },
  { value: "2-4h", name: "2-4 hours" },
  { value: "1d", name: "1 day" },
  { value: twoDaysValue, name: "2 days" },
  { value: threeDaysValue, name: "3 days" }
]

const getEventDuration = duration => durationOptions.find(({ value }) => value === duration)?.name || ""

const [firstDuration] = durationOptions
const defaultDuration = firstDuration.value

export default {
  name: "MultipleEvents",
  components: {
    EventDateInput,
    BlueHeaderModal
  },
  mounted() {
    this.userMultipleEvents = this.getPlanConfigValue("events")
  },
  data() {
    return {
      userMultipleEvents: [],
      userDate: null,
      typeOptions,
      durationOptions,
      type: defaultType,
      duration: defaultDuration,
      editEventIndex: -1,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        }
      }
    }
  },

  computed: {
    ...mapState("plan", ["view"]),
    ...mapGetters("plan", ["firstPrimaryEvent", "getPlanConfigValue"]),

    editingEvent() {
      return this.editEventIndex !== -1 ? this.userMultipleEvents[this.editEventIndex] : null
    },

    isTypeDisabled() {
      return this.editingEvent && this.editingEvent.primary
    }
  },
  methods: {
    resetForm() {
      this.userDate = null
      this.type = defaultType
      this.duration = defaultDuration
    },
    prepareEvent(forceOptions) {
      if (!this.userDate || !this.duration || !this.type) return

      return {
        eventDate: this.userDate,
        duration: this.duration,
        type: this.type,
        primary: false,

        ...forceOptions
      }
    },
    addEvent() {
      const event = this.prepareEvent()
      this.userMultipleEvents.push(event)
      this.userMultipleEvents.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))
      this.resetForm()
    },
    saveEvent() {
      this.userMultipleEvents[this.editEventIndex] = this.prepareEvent({ primary: this.editingEvent.primary })
      this.userMultipleEvents.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))
      this.resetForm()
      this.editEventIndex = -1
    },
    cancelEdit() {
      this.resetForm()
      this.editEventIndex = -1
    },
    getEvent(event) {
      return `${moment(event.eventDate).format("DD MMMM")}: ${getEventName(event.type)}, ${getEventDuration(
        event.duration
      )} ${event.primary ? "<b>(Primary)</b>" : ""}`
    },
    deleteEvent(eventIndex) {
      this.userMultipleEvents.splice(eventIndex, 1)
    },
    editEvent(eventIndex) {
      this.editEventIndex = eventIndex

      const { eventDate, type, duration } = this.userMultipleEvents[eventIndex]

      this.userDate = eventDate
      this.type = type
      this.duration = duration
    },
    save() {
      const multipleEvents = this.userMultipleEvents.filter(event => !event.primary)
      const [firstPrimaryEvent] = this.userMultipleEvents.filter(event => event.primary)
      this.$emit("onSave", [firstPrimaryEvent, ...multipleEvents])
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
p {
  font-weight: 500;
}
.table-container {
  min-height: 300px;
  font-size: 16px;
  padding: 0 55px;
  @include media("<tablet") {
    padding: 16px;
  }
}
.table {
  width: 100%;
}
.button-delete {
  color: $red;

  svg {
    width: 14px;
    height: 14px;
  }
}

.button-edit {
  color: $gray2;

  svg {
    width: 14px;
    height: 14px;
  }
}

.action-buttons {
  gap: 6px;
}

.table-multiple-events {
  td {
    padding: 4px 6px;
  }

  .td-date {
    width: 100%;
  }
}

.body-multiple-events {
  gap: 4px;
  padding: 0 55px;
  .action-button {
    height: 35px;
    padding: 4px 6px;
    @include media("<tablet") {
      width: 100%;
    }
  }

  .action-button + .action-button {
    margin-left: 4px;
  }
  @include media("<tablet") {
    padding: 16px;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: min-content min-content;
    .field {
      margin-bottom: 12px !important;
    }
    .field:last-child {
      .label {
        display: none;
      }
      grid-column: 1 / 4;
    }
  }
}
::v-deep .base-modal-wrapper {
  min-width: 100%;
}
::v-deep .blue-header-modal-container {
  max-width: 600px !important;
  min-width: 600px !important;
  @include media("<tablet") {
    min-width: 100% !important;
  }
}
::v-deep .blue-header-modal-header {
  .field {
    width: 80% !important;
  }
}
</style>
