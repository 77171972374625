<template>
  <div class="cj-field">
    <div class="grid">
      <div class="left">
        <div class="cj-label cj-label-flex">
          <div class="cj-label-title">
            {{ $t("blockHours.title") }}&nbsp; &nbsp;
            <InfoModal :text="$t('blockHours.infoText')" :title="$t('blockHours.infoTitle')" />
          </div>
          <SplitLongRides :block-split-long-rides="getBlockConfigValue('splitLongRides')" />
        </div>
        <div class="field inputs">
          <div class="hours-block cj-field">
            <div class="cj-label cj-label-title">{{ $t("blockHours.blockStartHours") }}</div>
            <div class="select mb-16">
              <select type="text" v-model="weeklyHoursMin">
                <option v-for="option in startDurationOptions" :key="option.value" :value="option.value">
                  {{ option.name }}
                </option>
              </select>
            </div>
            <div class="d-flex align-items-center gap-5">
              <input type="checkbox" id="fixedHours" name="fixedHours" class="checkbox-input" v-model="isFixedHours" />
              <label for="fixedHours" class="labels">
                {{ $t("blockHours.fixedHours") }}
              </label>
            </div>
          </div>
          <div class="hours-block cj-field" :class="{ 'block-disabled': isFixedHours }">
            <div class="cj-label cj-label-title">{{ $t("blockHours.blockEndHours") }}</div>
            <div class="select mb-16">
              <select type="text" v-model="weeklyHoursMax" :disabled="isEndHoursRecommended">
                <option v-for="option in endDurationOptions" :key="option.value" :value="option.value">{{
                  option.name
                }}</option>
              </select>
            </div>
            <div class="d-flex align-items-center gap-5">
              <input type="checkbox" id="index" name="index" class="checkbox-input" v-model="isEndHoursRecommended" />
              <label for="index" class="labels">
                {{ $t("blockHours.recommended") }}
              </label>
            </div>
          </div>
          <div class="cj-field">
            <div class="cj-label cj-label-title nowrap">{{ $t("blockHours.recoveryWeeks") }}:</div>
            <div class="d-flex justify-content-center">
              <Toggle v-model="recoveryPattern" :options="['3/1', '2/1']" />
            </div>
          </div>
        </div>
        <p>{{ $t("blockHours.infoText2") }}</p>
      </div>
      <BarChart
        :chartHeight="260"
        :weeks="!isLoading ? chartValues : []"
        :isMobile="isMobile"
        class="mb-8"
        :x-axis-label="barChartXAxis"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import BarChart from "@/components/plan/MainArea/RightArea/BarChart.vue"
import { i18n } from "@/main"
import moment from "moment/moment"
import Toggle from "@/components/Toggle.vue"
import {
  BLOCK_START_HOURS,
  BLOCK_END_HOURS,
  PEAK_BLOCK_START_HOURS,
  MAX_BASE_BLOCK_END_HOURS,
  MAX_BUILD_BLOCK_END_HOURS,
  EVENT_BLOCK_START_HOURS
} from "@/defaults"
import { BLOCK_TYPES } from "@/types"
import InfoModal from "@/components/InfoModal.vue"
import SplitLongRides from "@/components/plan/MainArea/Settings/SplitLongRides.vue"

export default {
  name: "HoursAndChart",
  components: {
    SplitLongRides,
    InfoModal,
    Toggle,
    BarChart
  },

  computed: {
    ...mapState(["isLoading"]),
    ...mapState("plan", ["view"]),
    ...mapGetters("plan", [
      "getPreviousBlock",
      "chartValues",
      "blockTypes",
      "getPlanConfigValue",
      "getBlockConfigValue"
    ]),
    ...mapGetters(["isMobile"]),

    activeBlock() {
      return this.view.activeBlock
    },

    recoveryPattern: {
      get() {
        return this.getBlockConfigValue("recoveryPattern") === "2/1"
      },
      set(isEnabled) {
        const value = isEnabled ? "2/1" : "3/1"
        this.updateActiveBlockConfigValue({ name: "recoveryPattern", value })
      }
    },
    isFixedHours: {
      get() {
        return this.getBlockConfigValue("isFixedHours")
      },
      set(value) {
        this.updateActiveBlockConfigValue({ name: "isFixedHours", value })
        if (this.activeBlock === BLOCK_TYPES.EVENT && !value) {
          this.updateActiveBlockConfigValue({ name: "isFixedHoursAuto", value: false })
        }
      }
    },
    startDurationOptions() {
      if (!this.getPlanConfigValue("blocks")) {
        return []
      }

      const indexPreviousBlock = this.getPlanConfigValue("blocks").findIndex(
        block => block.blockType === this.getPreviousBlock
      )
      const previousBlock = this.getPlanConfigValue("blocks")[indexPreviousBlock]

      const fromPreviousBlockHours = previousBlock && this.blockWeeklyHoursMin === previousBlock.weeklyHoursMax

      let blockStartHours = BLOCK_START_HOURS
      if (this.activeBlock === BLOCK_TYPES.PEAK) {
        blockStartHours = PEAK_BLOCK_START_HOURS
      }
      if (this.activeBlock === BLOCK_TYPES.EVENT) {
        blockStartHours = EVENT_BLOCK_START_HOURS
      }

      if (fromPreviousBlockHours) {
        return blockStartHours.map(el => {
          if (el === previousBlock.weeklyHoursMax) {
            return {
              value: el,
              name: `${this.$t("blockHours.from")} ${this.getPreviousBlock}: ${this.blockWeeklyHoursMin} ${this.$t(
                "blockHours.shortHoursPerWeek"
              )}`
            }
          }
          return { value: el, name: `${el} ${this.$t("blockHours.hoursPerWeek")}` }
        })
      }
      return blockStartHours.map(el => ({
        value: el,
        name: `${el} ${this.$t("blockHours.hoursPerWeek")}`
      }))
    },
    endDurationOptions() {
      let maxHoursByActiveBlock = BLOCK_END_HOURS[BLOCK_END_HOURS.length - 1]
      if (this.activeBlock === BLOCK_TYPES.BASE) {
        maxHoursByActiveBlock = MAX_BASE_BLOCK_END_HOURS
      } else if (this.activeBlock === BLOCK_TYPES.BUILD) {
        maxHoursByActiveBlock = MAX_BUILD_BLOCK_END_HOURS
      }

      if (this.isEndHoursRecommended)
        return [
          {
            value: this.weeklyHoursMax,
            name: `${this.$t("blockHours.automatic")}: ${this.weeklyHoursMax}${this.$t("blockHours.shortHoursPerWeek")}`
          }
        ]
      return BLOCK_END_HOURS.filter(hour => hour >= this.weeklyHoursMin && hour <= maxHoursByActiveBlock).map(hour => ({
        value: hour,
        name: `${hour} ${this.$t("blockHours.hoursPerWeek")}`
      }))
    },
    weeklyHoursMin: {
      get() {
        return this.getBlockConfigValue("weeklyHoursMin")
      },
      set(value) {
        this.updateActiveBlockConfigValue([
          { name: "weeklyHoursMin", value },
          { name: "isMinWeeklyMinutesAuto", value: false }
        ])
      }
    },
    weeklyHoursMax: {
      get() {
        return this.getBlockConfigValue("weeklyHoursMax")
      },
      set(value) {
        this.updateActiveBlockConfigValue({ name: "weeklyHoursMax", value })
      }
    },
    isEndHoursRecommended: {
      get() {
        return this.getBlockConfigValue("isEndHoursRecommended")
      },
      set(value) {
        this.updateActiveBlockConfigValue({ name: "isEndHoursRecommended", value })
      }
    },

    barChartXAxis() {
      const { locale } = i18n
      const weekCount = this.blockTypes?.filter(type => type === this.activeBlock).length
      return `${this.activeBlock === "any" ? "" : this.$t(`common.blockTypes.${this.activeBlock}`)} ${moment
        .duration(weekCount, "w")
        .locale(locale)
        .humanize({ d: 0, w: 30 })}`
    }
  },
  methods: {
    ...mapActions("plan", ["updateActiveBlockConfigValue"]),
    ...mapMutations(["setProfileFactsValue"])
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins.scss";
@import "@/assets/styles/theme.scss";
@import "@/assets/styles/helpers.scss";

.block-disabled:before {
  left: 0;
  top: 0;
}
.grid {
  @include cj-grid;

  @include media("<desktop") {
    grid-template-columns: none;
    grid-template-rows: auto 300px;
  }
}

.main-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inputs {
  display: grid;
  grid-template-columns: 1fr 1fr 160px;
  gap: 12px;

  @include media("<desktop-lg") {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.hours-block {
}

.inputs > * {
  flex: 1;
  background: #ffffff;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 16px;
}
.custom-btn {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
