<template>
  <div class="plan-root" v-if="hasPlanLoaded">
    <section class="container plan-main">
      <Header :name="profileFacts.firstName" />
      <div class="plan-tabs">
        <Tabs />
        <div class="plan-tabs-events">
          <StartingDate class="starting-date" :showDatepicker="true" :isEventBasedPlan="isEventTabOn" />
        </div>
      </div>

      <PlanWeeks v-if="!getPlanConfigValue('isEventBasedPlan')" />
      <PlanEventDate v-if="getPlanConfigValue('isEventBasedPlan')" />

      <div class="block-tabs">
        <BlockTabs :class="{ 'block-disabled': isBlockDisabled }" />
      </div>

      <div :class="{ 'block-disabled': isBlockDisabled }">
        <div class="grid-item grid-item-control">
          <BlockSettings />
        </div>
      </div>

      <div class="blue-background" :class="{ 'block-disabled': isBlockDisabled }">
        <div class="container mt-5 plan-calendar-header">
          <h3 class="plan-calendar-title">
            <img src="../../../assets/images/firework-icon.png" alt="Your full plan" />{{ $t("stepPlan.fullPlan") }}
          </h3>
          <WeeksSelector v-if="!isMobile" />
          <MobileWeeksPagination v-else />
        </div>

        <div class="plan-calendar-wrapper" :class="{ 'is-loading': isLoading }">
          <Calendar
            :isMobile="false"
            :only4weeks="only4weeks"
            :weekDays="weekDays"
            class="plan-calendar"
            :loading="isLoading || triggerResize"
            :blockTypes="blockTypes"
          />
          <img v-if="isLoading" class="plan-calendar-loader" src="../../../assets/images/loader.gif" alt="Loader" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import Calendar from "@/components/plan/Calendar/Calendar.vue"
import debounce from "lodash.debounce"
import WeeksSelector from "@/components/plan/Calendar/WeeksSelector.vue"
import PlanWeeks from "@/components/plan/PlanDurationAndEventDate/PlanWeeks.vue"
import PlanEventDate from "@/components/plan/PlanDurationAndEventDate/PlanEventDate.vue"
import BlockSettings from "@/components/plan/MainArea/Settings/BlockSettings.vue"
import BlockTabs from "@/components/plan/MainArea/Tabs/BlockTabs.vue"
import Header from "@/components/plan/Header/index.vue"
import Tabs from "@/components/plan/MainArea/Tabs/EventTabs.vue"
import StartingDate from "@/components/plan/StartingDate.vue"
import MobileWeeksPagination from "@/components/plan/Calendar/MobileWeeksPagination.vue"

export default {
  name: "PlanCreatorMainArea",
  components: {
    MobileWeeksPagination,
    StartingDate,
    BlockTabs,
    Tabs,
    BlockSettings,
    PlanWeeks,
    PlanEventDate,
    WeeksSelector,
    Calendar,
    Header
  },
  data() {
    return {
      triggerResize: false,
      debouncedResize: null
    }
  },
  computed: {
    ...mapState(["_id", "profileFacts", "isLoading"]),
    ...mapState("plan", ["view", "values", "config"]),

    ...mapGetters(["isBetaUser", "isMobile"]),
    ...mapGetters("plan", ["getBlockConfigValue"]),
    ...mapGetters("plan", [
      "blockTypes",
      "weekDays",
      "weeksWithoutEmptyDays",
      "chartValues",
      "getPlanConfigValue",
      "getTotalWeeksFromDates",
      "only4weeks"
    ]),
    hasPlanLoaded() {
      return !!this.config
    },
    isBlockDisabled() {
      return this.getTotalWeeksFromDates < 4
    },
    isEventTabOn() {
      return this.getPlanConfigValue("isEventBasedPlan")
    }
  },
  async beforeMount() {
    this.debouncedResize = debounce(() => {
      this.setIsMobile(window.innerWidth <= 767)

      this.triggerResize = true
      setTimeout(() => {
        this.triggerResize = false
      })
    }, 100)

    window.addEventListener("resize", this.debouncedResize)

    this.setIsMobile(window.innerWidth <= 767)
    const { historyId } = this.$route.query
    await this.changeAndGetPlan(historyId)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedResize)
  },
  methods: {
    ...mapActions("plan", ["changeAndGetPlan", "updatePlanConfigValue"]),
    ...mapMutations(["setIsMobile"])
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";
.labels {
  margin-left: 6px;
}
.justify-space-between {
  justify-content: space-between;
}

.justify-flex-end {
  justify-content: flex-end;
}
.block-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  @include media("<tablet") {
    flex-direction: column-reverse;
    gap: 12px;
  }
}
.plan-tabs-events {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  border: 2px solid $light-blue2;
  border-bottom: 0;
  border-radius: 10px 10px 0 0;
  padding: 0 8px;
  &::v-deep {
    .datepicker-link {
      border-bottom: 1px dashed $dark-blue;
      color: $dark-blue;
    }
    a {
      border: none;
      padding: 0;
    }
  }
  @include media("<desktop") {
    flex-direction: column;
    grid-gap: 24px;
    padding: 12px;
    width: 100%;
    border: none;
  }
}

.starting-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: normal;
  min-width: 475px;
  max-width: fit-content;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;

  @include media("<=tablet") {
    width: 100%;
    gap: 12px;
    margin-bottom: 8px;
  }
  @include media("<phone") {
    grid-template-columns: auto 200px;
    gap: 8px;
    margin-bottom: 8px;
  }
  a {
    padding: 0;
  }
}

.plan-tabs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: -11px;
  flex-wrap: wrap-reverse;
  @include media("<desktop") {
    margin-top: 0;
  }
}
.error {
  color: $red;
  text-align: center;
}
.plan {
  &-main {
    padding-top: 20px;
    padding-bottom: 100px;

    @include media("<tablet") {
      padding-bottom: 180px;
    }
  }

  &-main-data {
    display: flex;
    flex-direction: column;
  }

  &-calendar {
    width: 100%;

    @include media("<desktop-lg") {
      width: fit-content;
    }
    @include media("<tablet") {
      width: 100%;
    }
    &-title {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-wrapper {
      position: relative;
      margin: 0 auto;

      @include media("<desktop-lg") {
        //width: 100%;
        overflow-x: scroll;
        margin: 0 16px;
      }

      &.is-loading {
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.65);
        }
      }
    }

    &-loader {
      z-index: 1;
      position: absolute;
      left: 50%;
      margin-left: -64px;
      top: 160px;
      display: block;
      width: 185px;
    }
  }

  &-top {
    display: grid;
    grid-template-rows: 1fr auto;
    gap: 10px;
    align-items: end;
    margin-top: 10px;
    //margin-bottom: 25px;

    @include media("<desktop") {
      margin-bottom: 0;
    }

    img {
      max-height: 40px;
    }
  }

  &-actions {
    margin-top: 24px;
    margin-left: auto;
  }

  &-grid {
    display: grid;
    grid-template-columns: auto 475px;
    justify-content: space-between;
    gap: 12px;

    @include media("<desktop") {
      grid-template-columns: 1fr;
      overflow-x: hidden;
    }
  }

  &-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
  }

  &-info {
    border: $border-light-blue;
    border-radius: $border-radius;
    padding: 24px 23px 0 23px;

    @include media("<tablet") {
      border: none;
      padding: 0;
      border-top: $border-light-blue;
      border-bottom: $border-light-blue;
      border-radius: 0;
      margin: 0 -16px;

      & > * {
        padding: 16px 16px 0 16px;
      }
    }
    //display: grid;
    //grid-template-columns: 1fr 1fr;
    //width: 100%;
    //gap: 8px;
  }

  &-description {
  }

  &-finish-step {
    display: flex;
    flex-direction: row;
  }
}

.form-flex {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-head {
  width: 100%;
}

.img-button-td-logo {
  vertical-align: middle;
  margin-top: -1px;
}

.vertical-flex {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.action-buttons {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr auto auto;
  align-items: center;
}

.blue-background {
  background-color: $light-blue;
  border: $border-light-blue;
  border-radius: $border-radius;
  margin-top: 12px;
}

.gray-background {
  flex: 1;
  min-height: 100%;
  background-color: $gray9;
}

.plan-calendar {
  font-weight: 500;
  padding: 16px;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include media("<desktop") {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &-title {
    font-size: 18px;

    span {
      font-size: 24px;
      margin-right: 9px;
    }
  }

  @include media("<desktop") {
    padding-left: 0;
    padding-right: 0;
  }
}

.grid-item {
  position: relative;

  &-control {
    @include media("<desktop") {
      grid-row: 2;
    }
  }

  &-info {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media("<desktop") {
      grid-row: 1;
    }
  }

  &-control > * + * {
    margin-top: 10px;
  }

  &-border {
    padding: 20px 0;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      border-left: 1px solid #dbdbdb;
      top: 0;
      left: -20px;
      @include media("<desktop") {
        display: none;
      }
    }
  }
}

.show-options {
  @include media("<desktop") {
    display: block;
    padding-top: 20px;
  }
  display: none;
}

.edit-plan-form-modal {
  display: none !important;

  @include media("<desktop") {
    display: flex !important;
  }
}

.custom-br {
  display: block;

  @include media(">phone") {
    display: none;
  }
}
</style>
