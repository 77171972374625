<template>
  <div class="tabs is-boxed">
    <ul>
      <li :class="{ 'is-active': !getPlanConfigValue('isEventBasedPlan') }">
        <a @click="setIsEventTabOn(false)">{{ $t("stepPlan.weekPlan") }}</a>
      </li>
      <li :class="{ 'is-active': getPlanConfigValue('isEventBasedPlan') }">
        <a @click="setIsEventTabOn(true)">{{ $t("stepPlan.eventPlan") }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "Tabs",
  methods: {
    ...mapActions("plan", ["updatePlanConfigValue", "changeActiveWeek"]),

    setIsEventTabOn(value) {
      this.changeActiveWeek(1)
      this.updatePlanConfigValue({ name: "isEventBasedPlan", value })
    }
  },
  computed: {
    ...mapGetters("plan", ["getPlanConfigValue"])
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/mixins.scss";

.tabs {
  margin-bottom: 0;
  position: relative;
  border-bottom: none;
  border-radius: 6px 6px 0 0;
  overflow: visible;
  @include media("<phone") {
    &::v-deep {
      a {
        padding: 6px;
      }
    }
  }
}

.is-active {
  border: 2px solid #d9ecff;
  border-bottom: 0;
  z-index: 1;

  a {
    background-color: #ecf5ff !important;
  }
}

ul {
  border-style: none;
}

li {
  position: relative;
  margin-bottom: -2px;
  border: 2px solid transparent;
  border-bottom: none;
  border-radius: 6px 6px 0 0;
}

.tabs.is-boxed {
  a {
    border: none;
  }

  li:not(.is-active) {
    a:hover {
      box-shadow: inset 0 -2px 0 0 #d9ecff;
    }
  }
}

a {
  border-style: none;
  margin-bottom: 0;
}
</style>
