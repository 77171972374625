<template>
  <section class="date-picker">
    <date-picker
      v-model="getEventDate"
      format="YYYY-MM-DD"
      class="event-days"
      popup-class="event-days-popup"
      :clearable="false"
      :lang="lang"
      :disabled-date="dt => dt >= disableDates || dt < new Date()"
    />
  </section>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import moment from "moment"
import { mapActions, mapGetters, mapState } from "vuex"
import { DATE_FORMAT } from "@/types"
import { MAX_WEEKS_DURATION } from "@/defaults"

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      selected: null,
      highlighted: [],
      date: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        }
      }
    }
  },
  computed: {
    ...mapState("plan", ["plan"]),
    ...mapState(["profileFacts"]),
    ...mapGetters("plan", ["getPlanConfigValue", "firstPrimaryEvent"]),

    disableDates() {
      const startDate = this.getPlanConfigValue("startDate")
      return new Date(
        moment(startDate)
          .add(MAX_WEEKS_DURATION, "weeks")
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      )
    },
    getEventDate: {
      get() {
        return moment(this.firstPrimaryEvent.eventDate, DATE_FORMAT).toDate()
      },
      set(value) {
        const events = this.getPlanConfigValue("events")
        if (Array.isArray(events))
          events.map(event => {
            if (event.primary) {
              event.eventDate = moment(value).format(DATE_FORMAT)
            }
          })
        this.updatePlanConfigValue({ name: "events", value: events })
      }
    },
    sep() {
      return `      -      `
    }
  },
  methods: {
    ...mapActions("plan", ["updatePlanConfigValue"]),

    getClasses(date) {
      if (this.highlighted.find(v => v.getTime() === date.getTime())) {
        return "plan-start"
      }
      return ""
    },
    getWeeksAway(startDate, eventDate) {
      const a = moment(eventDate, "YYYY-MM-DD")
      const b = moment(startDate, "YYYY-MM-DD")
      const days = a.diff(b, "day") + 1
      let weeks = days / 7
      weeks = Math.ceil(weeks)
      if (weeks > MAX_WEEKS_DURATION) weeks = MAX_WEEKS_DURATION
      return weeks
    }
  }
}
</script>

<style lang="scss">
.event-days-popup {
  .mx-calendar-header .mx-btn-icon-double-right,
  .mx-calendar-header .mx-btn-icon-double-left {
    display: none;
  }
}
.event-days {
  .mx-input-wrapper {
    .mx-input {
      font-size: 16px;
    }
  }
}

.plan-start {
  background-color: rgba(45, 175, 0, 0.45) !important;
  color: white !important;
  position: relative;

  &:before {
    display: none;
    position: absolute;
    content: "Your plan starts here";
    width: 120px;
    height: 20px;
    top: -20px;
    left: 100%;
    color: black;
    background-color: #efdede;
  }

  &:hover {
    &:before {
      display: block;
    }
  }
}
</style>
