<template>
  <div>
    <div class="plan-name">
      <h2 class="plan-title">{{ name }}</h2>
    </div>
    <CutDescription v-if="description" :description="description" :isMobile="isMobile" />
  </div>
</template>

<script>
import CutDescription from "../../../CutDescription"
import { mapGetters } from "vuex"

export default {
  name: "PlanNameAndDescription",
  components: { CutDescription },
  computed: {
    ...mapGetters(["isMobile"])
  },
  props: {
    name: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

.plan {
  &-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 16px;

    span {
      color: $dark-blue;
    }
  }

  &-subtitle {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 12px;
  }
}
</style>
