<template>
  <div class="cj-field cj-field-simple-mode">
    <div class="left">
      <div class="cj-label cj-label-flex d-flex flex-wrap">
        <div class="cj-label-title">
          {{ $t("blockType.title") }}&nbsp; &nbsp;
          <InfoModal :text="$t('blockType.infoText')" :title="$t('blockType.infoTitle')" />
        </div>

        <template>
          <div class="d-flex gap-12 heading-links">
            <a
              href="#"
              class="link d-flex gap-5 align-items-center is-underline"
              @click.prevent="showSecondaryIntensityModal = true"
            >
              {{ $t("intensityLevel.adjustIntensity") }}
            </a>
            <a
              href="#"
              class="link d-flex gap-5 align-items-center is-underline"
              :class="{ blink: isClickedCustom }"
              @click.prevent="() => openCustomModal(HARDER_TAB)"
            >
              {{ $t("blockType.harder") }} <svg-icon icon="plus-rounded" />
            </a>
          </div>
        </template>
      </div>

      <div class="item3">
        <ButtonSelect :options="getTypeValues" v-model="planType">
          <div @click="() => openCustomModal(CUSTOM_TAB)" class="custom-button">
            <ButtonSelect :options="[{ name: PLAN_TYPES.CUSTOM, value: PLAN_TYPES.CUSTOM }]" v-model="customPlanType" />
          </div>
        </ButtonSelect>
      </div>

      <PlanTypeCustomModal
        v-if="showCustomPlanModal"
        @close="showCustomPlanModal = false"
        :planType="blockPlanType"
        @save="save"
        :customPlan="customPlan"
        :customPlanModalTab="customPlanModalTab"
      />
      <SecondaryIntensity
        v-if="showSecondaryIntensityModal"
        @close="showSecondaryIntensityModal = false"
        :feels="$t('secondaryIntensityModal.feels')"
        :blockSecondaryIntensity="getBlockConfigValue('secondaryIntensity')"
        :blockSecondaryType="getBlockConfigValue('secondaryType')"
        @saveSecondaryIntensity="saveSecondarySettings"
      />
    </div>

    <PlanNameAndDescription :description="getPlanInfo?.description" :name="getPlanInfo?.name" />
  </div>
</template>

<script>
import ButtonSelect from "@/components/ButtonSelect"
import PlanTypeCustomModal from "./CustomModal"
import {mapActions, mapGetters, mapMutations, mapState} from "vuex"
import InfoModal from "@/components/InfoModal"
import { CUSTOM_TAB, HARDER_TAB } from "./custom-modal-tabs"
import PlanNameAndDescription from "@/components/plan/MainArea/RightArea/PlanNameAndDescription.vue"
import SecondaryIntensity from "@/components/plan/MainArea/Settings/SecondaryIntensity.vue"
import { PLAN_TYPES } from "@/types"

export default {
  name: "BlockTypeAndPlanDescription",
  components: {
    SecondaryIntensity,
    PlanNameAndDescription,
    ButtonSelect,
    PlanTypeCustomModal,
    InfoModal
  },
  data() {
    return {
      showSecondaryIntensityModal: false,
      showCustomPlanModal: false,
      customPlanModalTab: null,
      customPlanType: "",
      isClickedCustom: false,
      secondaryIntensity: 0,
      blockSecondaryIntensity: 0,
      CUSTOM_TAB,
      HARDER_TAB,
      PLAN_TYPES
    }
  },
  watch: {
    customPlanType() {
      if (
        localStorage.getItem(PLAN_TYPES.CUSTOM) === null ||
        !JSON.parse(localStorage.getItem(PLAN_TYPES.CUSTOM))[this.view.activeBlock]
      ) {
        this.setDefaultCustom()
      }
    },
    blockSecondaryIntensity(value) {
      this.secondaryIntensity = value
    }
  },
  computed: {
    ...mapState("plan", ["view", "values", "config"]),
    ...mapGetters("plan", ["getBlockConfigValue", "getPlanInfo", "getPlanTypesForBlock", "getPlanConfigValue"]),
    planType: {
      get() {
        return this.blockPlanType
      },
      set(value) {
        if (value && this.values) {
          this.customPlanType = this.changePlanType(value)
        }

        if (value === PLAN_TYPES.CUSTOM) {
          this.blinkCustom()
        }
      }
    },
    isSingleBlockOn() {
      return this.getPlanConfigValue("isSingleBlockOn")
    },

    blockPlanType: {
      get() {
        return this.getBlockConfigValue("planType")
      },
      set(value) {
        this.planType = value
      }
    },
    customPlan() {
      return this.getBlockConfigValue("customPlan")
    },

    getTypeValues() {
      return this.getPlanTypesForBlock
    }
  },
  methods: {
    ...mapActions("plan", ["updatePlanConfigValue", "changePlanType", "updateActiveBlockConfigValue"]),
    ...mapMutations("plan", ["setActiveBlockConfigValue"]),

    saveSecondarySettings({ secondaryIntensity, secondaryType, currentIntervalIntensityLevel }) {
      this.updateActiveBlockConfigValue([
        { name: "secondaryIntensity", value: secondaryIntensity },
        { name: "secondaryType", value: secondaryType },
        { name: "currentIntervalIntensityLevel", value: currentIntervalIntensityLevel }
      ])
      this.secondaryIntensity = secondaryIntensity
      this.showSecondaryIntensityModal = false
    },
    setDefaultCustom() {
      if (this.config.blocks.length > 0) {
        this.config.blocks.forEach(block => {
          localStorage.setItem(
            PLAN_TYPES.CUSTOM,
            JSON.stringify({
              ...JSON.parse(localStorage.getItem(PLAN_TYPES.CUSTOM)),
              [block.blockType]: PLAN_TYPES.CUSTOM
            })
          )
        })
      } else {
        JSON.stringify({ ...JSON.parse(localStorage.getItem(PLAN_TYPES.CUSTOM)), any: PLAN_TYPES.CUSTOM })
      }
    },

    setDefaultPlanTypeIfUnavailable() {
      const currentBasePlanTypes = this.getTypeValues.map(i => i.value)
      const currentHarderPlanTypes = this.values.harderTypeValues.map(i => i.value)
      if (this.planType === PLAN_TYPES.CUSTOM) {
        this.customPlanType = PLAN_TYPES.CUSTOM
        return
      }
      if (!currentBasePlanTypes.includes(this.planType) && !currentHarderPlanTypes.includes(this.planType)) {
        this.planType = currentBasePlanTypes[0]
      }
    },

    closeCustomModal() {
      this.showCustomPlanModal = false
    },

    save({ customPlan, isNew, isHarder }) {
      if (isHarder) {
        this.customPlanType = PLAN_TYPES.CUSTOM
        localStorage.setItem(
          PLAN_TYPES.CUSTOM,
          JSON.stringify({
            ...JSON.parse(localStorage.getItem(PLAN_TYPES.CUSTOM)),
            [this.view.activeBlock]: this.getBlockConfigValue("planType")
          })
        )

        this.closeCustomModal()
        return
      }
      if (isNew) {
        this.setActiveBlockConfigValue({ name: "planType", value: PLAN_TYPES.CUSTOM })
      }
      localStorage.setItem(
        PLAN_TYPES.CUSTOM,
        JSON.stringify({
          ...JSON.parse(localStorage.getItem(PLAN_TYPES.CUSTOM)),
          [this.view.activeBlock]: PLAN_TYPES.CUSTOM
        })
      )
      this.setActiveBlockConfigValue({ name: "customPlan", value: customPlan })
      this.customPlanType = PLAN_TYPES.CUSTOM
      this.planType = PLAN_TYPES.CUSTOM

      this.closeCustomModal()
    },

    blinkCustom() {
      this.isClickedCustom = true
      setTimeout(() => {
        this.isClickedCustom = false
      }, 3000)
    },

    openCustomModal(tab = HARDER_TAB) {
      this.customPlanModalTab = tab
      this.showCustomPlanModal = true
    }
  },
  mounted() {
    this.secondaryIntensity = this.getBlockConfigValue("secondaryIntensity")
    if (this.values.harderTypeValues.map(type => type.value).includes(this.blockPlanType)) {
      this.customPlanType = PLAN_TYPES.CUSTOM
    }
    this.setDefaultPlanTypeIfUnavailable()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
@import "@/assets/styles/theme.scss";

.cj-field-simple-mode {
  @include cj-grid;

  @include media("<desktop") {
    grid-template-columns: none;
  }
}
.blink {
  animation: blinker 1s linear infinite;
}

.link-button {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  color: $blue;
}

@keyframes blinker {
  50% {
    background-color: $build;
  }
}

.custom-button {
  cursor: pointer;
  &::v-deep {
    .has-tooltip-arrow:hover {
      label {
        background: lighten($dark-blue-alpha, 20);
        color: $blue;
      }
    }
    label {
      pointer-events: none;
    }
  }
}
.cj-label-flex {
  @include media("<tablet") {
    flex-direction: row;
    justify-content: space-between;
  }
}

.heading-links {
  font-size: 12px;
}

.item3 {
  grid-area: col3;
}
</style>
