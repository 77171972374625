<template>
  <BlueHeaderModal @close="$emit('close')">
    <template slot="header">
      <label class="label">{{ $t("secondaryIntensityModal.title") }}</label>
      <div class="has-text-white">
        {{ $t("secondaryIntensityModal.text") }}
      </div>
    </template>
    <template slot="body">
      <IntensityLevel
        :block-current-interval-intensity-level="getBlockConfigValue('currentIntervalIntensityLevel')"
        :block-secondary-intensity="getBlockConfigValue('secondaryIntensity')"
        :block-secondary-type="getBlockConfigValue('secondaryType')"
        @currentIntervalIntensityLevel="handleIntervalIntensityLevel"
      />
      <div class="modal_wrapper">
        <div>
          <div class="input-row" v-for="(feel, index) in feelsLeft" :key="index">
            <div class="input-container">
              <div class="input-wrapper">
                <input
                  type="radio"
                  :id="`left-${index}`"
                  name="left-group"
                  class="radio-input"
                  v-model="secondaryIntensity"
                  :value="getTypeValues[index].value"
                  @input="onChangeSecondaryIntensity"
                />
              </div>
              <label :for="index" class="labels">
                {{ feel.text }}
                <small class="checkbox-subtext small-labels">{{ feel.description }}</small>
              </label>
            </div>

            <div v-if="getTypeValues[index].value === -8" class="inner-wrap">
              <div class="input-row inner-input-row">
                <Toggle v-model="secondaryState.slopeAsSecondary" :disabled="!isSecondaryTypeChangeAvailable">
                  <div class="labels">
                    {{ $t("secondaryIntensityModal.slopeAsSecondaryCheckbox.title") }}
                    <small class="checkbox-subtext small-labels">
                      {{ $t("secondaryIntensityModal.slopeAsSecondaryCheckbox.desc") }}
                    </small>
                  </div>
                </Toggle>
              </div>
              <div class="input-row inner-input-row">
                <Toggle v-model="secondaryState.secondaryInFront" :disabled="!isSecondaryTypeChangeAvailable">
                  <div class="labels">
                    {{ $t("secondaryIntensityModal.secondaryInFrontCheckbox.title") }}
                    <small class="checkbox-subtext small-labels">
                      {{ $t("secondaryIntensityModal.secondaryInFrontCheckbox.desc") }}
                    </small>
                  </div>
                </Toggle>
              </div>
            </div>
          </div>
        </div>
        <div class="input-row-right">
          <div class="input-row" v-for="(feel, index) in feelsRight" :key="index">
            <div class="input-container">
              <div class="input-wrapper">
                <input
                  type="radio"
                  :id="`right-${index}`"
                  name="right-group"
                  class="radio-input"
                  v-model="secondaryIntensity"
                  :value="getTypeValues[index + feelsLeft.length].value"
                  @input="onChangeSecondaryIntensity"
                />
              </div>
              <label :for="index" class="labels">
                {{ feel.text }}
                <small class="checkbox-subtext small-labels">{{ feel.description }}</small>
              </label>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <button class="button is-full-width is-green" @click="save">
        <span>{{ $t("common.save") }}</span>
      </button>
    </template>
  </BlueHeaderModal>
</template>

<script>
import BlueHeaderModal from "@/components/BlueHeaderModal"
import Toggle from "@/components/Toggle"
import { mapGetters, mapState } from "vuex"
import IntensityLevel from "@/components/plan/MainArea/Settings/IntensityLevel.vue"

export default {
  name: "SecondaryIntensity",
  components: {
    IntensityLevel,
    BlueHeaderModal,
    Toggle
  },
  props: {
    feels: {
      type: Array,
      required: true
    },
    blockSecondaryIntensity: {
      type: Number,
      default: null
    },
    blockSecondaryType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      secondaryIntensity: 0,
      currentIntervalIntensityLevel: 0,
      secondaryState: {
        slopeAsSecondary: false,
        secondaryInFront: false
      }
    }
  },

  methods: {
    onChangeSecondaryIntensity(event) {
      const { target } = event
      if (Number(target.value) === -8) {
        this.secondaryState.slopeAsSecondary = true
        this.secondaryState.secondaryInFront = true
      } else {
        this.secondaryState.slopeAsSecondary = false
        this.secondaryState.secondaryInFront = false
      }
    },
    handleIntervalIntensityLevel(value) {
      this.currentIntervalIntensityLevel = value
    },
    save() {
      let secondaryType = "default"

      if (this.isSecondaryTypeChangeAvailable) {
        const { slopeAsSecondary, secondaryInFront } = this.secondaryState

        if (slopeAsSecondary && secondaryInFront) {
          secondaryType = "slope_as_secondary_in_front"
        } else if (slopeAsSecondary) {
          secondaryType = "slope_as_secondary"
        } else if (secondaryInFront) {
          secondaryType = "secondary_in_front"
        }
      }
      const payload = {
        secondaryIntensity: this.secondaryIntensity,
        secondaryType,
        currentIntervalIntensityLevel: this.currentIntervalIntensityLevel
      }
      this.$emit("saveSecondaryIntensity", payload)
    }
  },
  mounted() {
    if (typeof this.blockSecondaryIntensity == "number") {
      this.secondaryIntensity = this.blockSecondaryIntensity
    }
    if (this.isSecondaryTypeChangeAvailable) {
      if (this.blockSecondaryType === "slope_as_secondary_in_front") {
        this.secondaryState.slopeAsSecondary = true
        this.secondaryState.secondaryInFront = true
      }

      if (this.blockSecondaryType === "slope_as_secondary") {
        this.secondaryState.slopeAsSecondary = true
        this.secondaryState.secondaryInFront = false
      }

      if (this.blockSecondaryType === "secondary_in_front") {
        this.secondaryState.slopeAsSecondary = false
        this.secondaryState.secondaryInFront = true
      }
    }
  },
  computed: {
    ...mapState("plan", ["values"]),
    ...mapGetters("plan", ["getBlockConfigValue"]),
    feelsLeft() {
      return [this.feels[0]]
    },
    feelsRight() {
      return this.feels.slice(1)
    },
    isSecondaryTypeChangeAvailable() {
      return this.secondaryIntensity === -8
    },
    getTypeValues() {
      const { secondaryIntensityValues } = this.values
      return secondaryIntensityValues
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins.scss";
@import "@/assets/styles/variables";

p {
  font-weight: 500;
}
.labels {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  cursor: pointer;
}
.small-labels {
  opacity: 0.5;
}
.radio-input {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: auto 0;
}
.radio-input:disabled + .labels {
  opacity: 0.5;
}
.modal_wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  @include media("<tablet") {
    flex-direction: column;
  }
}
.input-container {
  display: flex;
  flex-direction: row;
}
.input-container-right {
  display: flex;
  flex-direction: column;
}
.input-wrapper {
  margin: auto 0;
}
.input-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px 0;
  &-right {
    min-width: 40%;
    margin-left: 8px;
  }
}

.inner-wrap {
  padding: 5px 0 0 30px;
  width: 100%;
}

.inner-input-row {
  padding: 0;
}

::v-deep {
  .blue-header-modal-container {
    max-width: 600px;
    min-width: 500px;
    @include media("<tablet") {
      max-width: 100%;
      min-width: 100%;
    }
  }
  .blue-header-modal-footer {
    padding: 30px;
  }

  .blue-header-modal-header {
    padding: 33px 50px 30px 30px;
    margin-bottom: unset;
    .field {
      width: 100%;
    }
  }
  .blue-header-modal-body {
    padding: 33px 50px 0 30px;
  }
}
span {
  color: #ffffff;
  font-weight: 500;
}
</style>
