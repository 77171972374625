<template>
  <BlueHeaderModal @close="$emit('close')">
    <template v-slot:header>
      <label class="label field-days-off">{{ $t("planDuration.plannedDaysOff") }}</label>
    </template>
    <template v-slot:body>
      <div class="body-days-off is-flex is-justify-content-space-between">
        <div class="field  text-left">
          <label class="label">Date</label>
          <RangeDateInput
            :userListDaysOff="userListDaysOff"
            :dateRange="userInput"
            @setInputCalendar="setInputCalendar"
          />
        </div>
        <div class="field text-left">
          <label class="label">&nbsp;</label>
          <button
            v-if="editDateIndex === -1"
            class="button action-button"
            :class="userInput.length ? 'is-red' : 'is-green'"
            @click="addDaysOff"
          >
            ADD
          </button>
          <div class="is-flex" v-else>
            <button class="button is-green action-button" @click="saveDate">
              SAVE
            </button>
            <button class="button is-light action-button" @click="cancelEdit">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div class="table-container">
        <div class="table-empty" v-if="!userListDaysOff.length">
          {{ $t("planDuration.noDaysOff") }}
        </div>
        <table class="table is-bordered is-striped table-days-off">
          <thead v-if="userListDaysOff.length">
            <tr>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(date, dateIndex) in userListDaysOff" :key="dateIndex">
              <td class="td-date">{{ getRangeDate(date) }}</td>
              <td>
                <div class="is-flex is-justify-content-center action-buttons">
                  <a class="button-delete" @click="deleteDayOff(dateIndex)">
                    <svg-icon icon="delete" />
                  </a>
                  <a class="button-edit" @click="editDayOff(dateIndex)">
                    <svg-icon icon="edit" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-slot:footer>
      <button class="button is-full-width is-green" @click="save" :disabled="editingDayOff">
        <span>{{ $t("common.save") }}</span>
      </button>
    </template>
  </BlueHeaderModal>
</template>

<script>
import BlueHeaderModal from "@/components/BlueHeaderModal"
import RangeDateInput from "@/components/plan/PlanDurationAndEventDate/RangeDateInput.vue"
import moment from "moment"
export default {
  name: "DaysOff",
  components: {
    RangeDateInput,
    BlueHeaderModal
  },
  data() {
    return {
      userListDaysOff: [],
      userInput: [],
      editDateIndex: -1
    }
  },
  mounted() {
    this.userListDaysOff = this.userListDaysOff.concat(this.dateRange)
  },
  props: {
    dateRange: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    editingDayOff() {
      return this.editDateIndex !== -1 ? this.userListDaysOff[this.editDateIndex] : null
    }
  },
  methods: {
    addDaysOff() {
      this.userListDaysOff = this.userInput ? this.userListDaysOff.concat(this.userInput) : this.userListDaysOff
      this.userListDaysOff.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
      this.userInput = []
    },
    deleteDayOff(dateIndex) {
      this.userListDaysOff.splice(dateIndex, 1)
    },
    editDayOff(dateIndex) {
      this.editDateIndex = dateIndex
      this.userInput = [this.userListDaysOff[dateIndex]]
    },
    saveDate() {
      const [userInput] = this.userInput
      this.userListDaysOff[this.editDateIndex] = userInput
      this.userListDaysOff.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
      this.userInput = []
      this.editDateIndex = -1
    },
    cancelEdit() {
      this.userInput = []
      this.editDateIndex = -1
    },
    setInputCalendar(data) {
      this.userInput = data
    },
    getRangeDate(rangeDate) {
      return `${moment(rangeDate.startDate).format("DD MMMM")}   -   ${moment(rangeDate.endDate).format("DD MMMM")}`
    },
    save() {
      this.$emit("saveDaysOff", this.userListDaysOff)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

.is-flex {
  flex-wrap: wrap;
}
p {
  font-weight: 500;
}
.table-empty {
  margin-top: 35%;
  margin-left: 17%;
}
.field-days-off {
  width: 60% !important;
}
.table-container {
  min-height: 300px;
  font-size: 16px;
  padding: 0 55px;
  @include media("<tablet") {
    padding: 0 36px;
  }
}
.table {
  width: 100%;
}
.button-delete {
  color: $red;

  svg {
    width: 14px;
    height: 14px;
  }
}

.button-edit {
  color: $gray2;

  svg {
    width: 14px;
    height: 14px;
  }
}

.action-buttons {
  gap: 6px;
}

.table-days-off {
  td {
    padding: 4px 6px;
  }

  .td-date {
    width: 100%;
  }
}
.body-days-off {
  gap: 4px;
  padding: 0 55px;
  @include media("<tablet") {
    padding: 0 36px;
  }
  .action-button {
    height: 35px;
    padding: 4px 6px;
  }

  .action-button + .action-button {
    margin-left: 4px;
  }
}

::v-deep .base-modal-wrapper {
  min-width: 100%;
}
::v-deep .blue-header-modal-container {
  max-width: 600px !important;
  min-width: 600px !important;
  @include media("<tablet") {
    min-width: 100% !important;
  }
}
::v-deep .blue-header-modal-header {
  .field {
    width: 80% !important;
  }
}
</style>
