<template>
  <div class="cj-label-title">
    <input
      type="checkbox"
      id="index"
      name="index"
      class="checkbox-input"
      v-model="splitLongRides"
      @click="saveSplitLongRides(!splitLongRides)"
    />
    <label for="index" class="labels">
      {{ $t("splitLongRides.infoTitle") }}
    </label>
    <InfoModal :text="$t('splitLongRides.infoText')" :title="$t('splitLongRides.infoTitle')" />
  </div>
</template>

<script>
import InfoModal from "@/components/InfoModal"
import { mapActions } from "vuex"

export default {
  name: "SplitLongRides",
  props: {
    blockSplitLongRides: {
      type: Boolean,
      default: false
    }
  },
  components: { InfoModal },
  watch: {
    blockSplitLongRides(value) {
      this.splitLongRides = value
    }
  },
  data() {
    return {
      splitLongRides: this.blockSplitLongRides
    }
  },
  methods: {
    ...mapActions("plan", ["updateActiveBlockConfigValue"]),
    saveSplitLongRides(splitLongRides) {
      this.updateActiveBlockConfigValue({ name: "splitLongRides", value: splitLongRides })
      this.splitLongRides = splitLongRides
    }
  },
  mounted() {
    this.splitLongRides = this.blockSplitLongRides
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";
.checkbox-input {
  margin: 8px;
  cursor: pointer;
  width: 15px;
  height: 15px;
  @include media("<=tablet") {
    width: 25px;
    height: 25px;
  }
}
.labels {
  cursor: pointer;
}
</style>
